<template>
  <base-section
    :id="scrollId"
    space="96"
  >
    <v-container>
      <v-row justify="space-between">
        <v-col
          cols="12"
          md="5"
          :class="{'order-1': left}"
        >
          <base-business-info
            :business="business"
            :title="title"
          />
        </v-col>

        <v-col
          cols="12"
          md="6"
          :class="{'order-0': left}"
        >
          <l-map
            v-if="showMap"
            :zoom="zoom"
            :center="place.geo"
            :options="mapOptions"
            style="min-height: 500px"
            @update:center="centerUpdate"
            @update:zoom="zoomUpdate"
          >
            <l-tile-layer
              :url="url"
              :attribution="attribution"
            />
            <l-marker
              :key="place.address"
              :lat-lng="place.geo"
            >
              <l-tooltip :options="{ permanent: true, interactive: true }">
                <div>
                  {{ place.address }}
                </div>
              </l-tooltip>
            </l-marker>
          </l-map>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  import { latLng, Icon } from 'leaflet'
  import { LMap, LTileLayer, LMarker, LPopup, LTooltip } from 'vue2-leaflet'

  delete Icon.Default.prototype._getIconUrl
  Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  })

  export default {
    name: 'SectionKeepInTouch',
    components: {
      LMap,
      LTileLayer,
      LMarker,
      LTooltip,
    },
    props: {
      business: {
        type: Array,
      },
      place: {
        type: Object,
      },
      title: {
        type: String,
      },
      left: {
        type: Boolean,
      },
      scrollId: {
        type: String,
      },
    },
    data () {
      return {
        zoom: 8,
        center: latLng(52.594140, 19.653090),
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        withPopup: latLng(47.41322, -1.219482),
        withTooltip: latLng(47.41422, -1.250482),
        currentZoom: 4.5,
        currentCenter: latLng(52.594140, 19.653090),
        showParagraph: false,
        mapOptions: {
          zoomSnap: 0.5,
          scrollWheelZoom: false,
          touchZoom: false,
        },
        showMap: true,
      }
    },
    methods: {
      zoomUpdate (zoom) {
        this.currentZoom = zoom
      },
      centerUpdate (center) {
        this.currentCenter = center
      },
    },
  }
</script>
