<template>
  <section-place-info
    :business="business"
    :place="place"
    :title="translations.contactPlaceInfoFourthTitle"
    :left="true"
    scroll-id="placeFourthCity"
  />
</template>

<script>
  import SectionPlaceInfo from '@/views/sections/reusable/PlaceInfo'
  import { latLng } from 'leaflet'
  import { mapState } from 'vuex'
  import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
  import { STORE_NAMESPACE_CONTACT } from '@/views/contact/Index.vue'

  export default {
    name: 'SectionContactPlaceInfoFourth',
    components: {
      SectionPlaceInfo,
    },
    computed: {
      ...mapState(STORE_NAMESPACE_CONTACT, [
        'translations',
      ]),
      business () {
        return [
          {
            icon: 'mdi-map-marker-outline',
            title: 'Address',
            text: this.translations?.contactPlaceInfoFourthLocale,
          },
          {
            icon: 'mdi-cellphone',
            title: 'Phone',
            text: documentToHtmlString(this.translations?.contactPlaceInfoFourthPhones),
          },
          {
            icon: 'mdi-email',
            title: 'Email',
            text: this.translations?.contactPlaceInfoFourthEmail,
          },
        ]
      },
    },
    data: () => ({
      title: 'ODDZIAŁ W PUŁAWACH',
      place:
        {
          geo: latLng(51.451840, 21.980860),
          address: 'Aleja 1000-Lecia Państwa Polskiego 13\n 24-100 Puławy',
        },

    }),
  }
</script>
